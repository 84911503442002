<template>
    <main class="preview">
        <div id="logo">
            <a
                href=""
                title="">
                <img
                    src="/gfx/logo-thueringen.png"
                    alt="Logo zur Messe">
            </a>
        </div>


        <div class="box-404">
            <h1>Fehler 404 </h1>
            <small>Die Seite auf welche Sie zugreifen möchten existiert nicht</small>
            <a
                :href="thaffUrl"
                title="Zur Thaff Seite"
                class="btn">Zurück zur Startseite</a>
        </div>

        <img
            src="/img/404.jpg"
            alt="404 - Seite nicht gefunden">
    </main>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    name: "ErrorNotFound",
    computed: {
        thaffUrl(): string {
            return process.env.VUE_APP_THAFF_URL;
        }
    }
})
</script>

<style scoped>

</style>
