
import {defineComponent} from "vue";

export default defineComponent({
    name: "ErrorNotFound",
    computed: {
        thaffUrl(): string {
            return process.env.VUE_APP_THAFF_URL;
        }
    }
})
